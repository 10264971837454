.withdrawallist-page {
  height: 100vh;
  background-color: #ffffff;
  padding: 20px 10px;
  /* display: flex;
    flex-direction: column; */
}

.data_out {
  width: 100%;
  background-color: #f7f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 12px 10px;
  margin-bottom: 10px;
  border: 1px dashed #fff;
}

.data_ouB {
  width: 100%;
  background-color: #f7f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 12px 10px;
  margin-bottom: 10px;
  border: 1px dashed #f03550;
}

.data_out_text {
  margin-left: 20px;
  text-align: left;
  flex: 1;
}

.data_right {
  display: flex;
  align-items: center;
}

.add_box {
  width: 100%;
  padding: 20px 0 15px;
  border: 1px dashed #d9d9d9;
  border-radius: 5px;
}
.add_box_text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add_text {
  color: #999999;
}
